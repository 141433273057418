/**Footer**/
.footer {background:#015536; position:relative;}
.fooer_strip {width:100%; margin-top:40px; }
.footer_top {padding:80px 0;}
.f_logo_group h5{color:#fff; font-weight:400;}
.fooer_strip img{width:100%;}

.f_menu ul {display:flex; flex-direction:row; justify-content:center;}
.f_menu ul li {margin:0 11px 10px;}
.f_menu ul li a {color:#fff; font-size:16px; text-transform:uppercase;}
.f_menu ul li a:hover {color:#ffc913;}
.other_logos {margin-top:20px;}
.cp_menu li {display:inline-block; color:#fff; font-size:12px; vertical-align:middle; margin:0 10px}
.cp_menu li a {color:#fff; font-size:12px;}

.footer_btm {background:#033a26; padding:10px;}
.copyrhted_policy li{display:inline-block; vertical-align:middle; color:#fff; font-size:12px; }
.copyrhted_policy li a {color:#ffc913; font-size:12px;}
.copyrhted_policy li a:hover {text-decoration:none;}
.copyrhted_policy li:after {
	content:'|';
	font-size:18px;
	color:#fff;
	display:inline-block;
	margin:0 10px;
}
.copyrhted_policy li:last-child:after {
	display:none;
}


@media (min-width:1600px){
.f_menu ul li a {font-size:18px;}
}	

@media (max-width:767px){
.f_menu ul li a {font-size:14px;}
}