@import url('https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
 body {
	margin: 0;
	padding: 0;
	color: #353535;
	background:#e3ede2 ;
	font-family: 'Rubik', sans-serif; 
}
ul, li {
	margin: 0;
	padding: 0;
	list-style-type: none;
}
a, a:hover, a:focus {
	text-decoration: none;
	outline: none;
}
img {
	max-width: 100%;
}
h1, h2, h3, h4, h5, h6, p {
	margin: 0;
}
* {
	box-sizing: border-box;
}

/*********************
// Start Homepage //
***********************/
.ban-Txtwrap > h4 > span {
	display: none;
}
.open_Md9 .ban-Txtwrap > h4 > span {
	display: inline;
}
.binny-Mosco {
	padding: 0 50px 50px;
}
.left-Menuse .logo-Main {
	padding: 50px 10px;
	text-align:center;
}
.left-Menuse .logo-Main img {
	max-width: 200px;
}
.close_nav {
	overflow: hidden;
}
.main-Menuse #navbarSupportedContent {
	display: none !important;
}
.open_Md9 .main-Menuse #navbarSupportedContent {
	display: flex !important;
}
header {
	position:absolute;
	left:0;
	right:0;
	top:0;
	padding:20px 0;
	z-index:99999;
	-webkit-transition: all 400ms ease-in-out 0s;
	-moz-transition: all 400ms ease-in-out 0s;
	-ms-transition: all 400ms ease-in-out 0s;
	-o-transition: all 400ms ease-in-out 0s;
	transition: all 400ms ease-in-out 0s;
}
.left-Menuse {
	position: fixed;
	left: 0;
	top: 0;
	bottom: 0;
	width: 320px;
	background: #58aa55;
	box-shadow: 0px 1px 3px 1px #2c2c2c;
	z-index: 99999;
	-webkit-transition: all 400ms ease-in-out 0s;
	-moz-transition: all 400ms ease-in-out 0s;
	-ms-transition: all 400ms ease-in-out 0s;
	-o-transition: all 400ms ease-in-out 0s;
	transition: all 400ms ease-in-out 0s;
	transform: translate(-102%);
}
.close_nav .left-Menuse {
	transform: translate(0);
}
.left-Menunab ul > li > a > i {
	color: #ffdb31;
	padding-right: 10px;
	font-size: 16px;
}
.left-Menunab ul > li > a {
	font-size: 14px;
	background: #58aa55;
	display: block;
	color: #fff;
	padding: 15px 15px 15px 30px;
	border-top: 1px solid #4c864a;
	-webkit-transition: all 400ms ease-in-out 0s;
	-moz-transition: all 400ms ease-in-out 0s;
	-ms-transition: all 400ms ease-in-out 0s;
	-o-transition: all 400ms ease-in-out 0s;
	transition: all 400ms ease-in-out 0s;
}
.left-Menunab ul > li > a:hover {
	background: #ffdb31;
	color:#2c2c2c
}
.left-Menunab ul > li > a:hover i {
	color: #00005e;
}
/*****************
//steps css //
****************/

.right-Menuse, .close_nav .right-Menuse, .right-Menuse2, .close_nav .right-Menuse2, .right-Menuse3, .close_nav .right-Menuse3, .right-Menuse4, .close_nav .right-Menuse4, .right-Menuse5, .close_nav .right-Menuse5 {
	background:#ffffff;
	padding:0 20px 20px;
	width:480px;
	left: auto;
	right: 0;
	transform: translate(102%);
	overflow-y:scroll;
}
.left-Menuse.right-Menuse4 {
	background: #58aa55;
}
.off-nav > a {
	transform: translate(460px);
	z-index: 999999999;
	position:fixed !important;
	top:10px !important;
	
}
.open_side .right-Menuse, .open_side1 .right-Menuse2, .open_side2 .right-Menuse3, .open_side3 .right-Menuse4, .open_side4 .right-Menuse5, .open_side5 .left-Menuse.right-Menuse5 {
	transform: translate(0);
}
.open_utan1 .sek-Popse.right-Menuse3, .open_utan1.open_utan2 .right-Menuse3.utanStp2, .open_utan1.open_utan3 .right-Menuse4.utan-Stp3 {
	transform: translate(102%);
}
.open_utan1 .right-Menuse3.utanStp2, .open_utan2 .right-Menuse4.utan-Stp3, .open_utan3 .right-Menuse4.utan-Stp4 {
	transform: translate(0);
}
.open_side .off-nav > a, .open_side1 .off-nav > a, .open_side2 .off-nav > a, .open_side3 .off-nav > a, .open_side4 .off-nav > a, .open_side5 .off-nav > a {
	transform: translate(-455px);
	z-index: 999999999;
}
.open_side, .open_side1, .open_side2, .open_side3, .open_side4, .open_side5 {
	overflow:hidden;
}
.img-Bkd {
	margin: 40px 0;
	border-radius: 10px;
	overflow: hidden;
	box-shadow: 1px 1px 0px 1px #2c2c2c;
}
.step-Wrapse ul > li {
	font-size: 14px;
	color: #fff;
	line-height: 18px;
	font-weight: 400;
	list-style-type: decimal;
	margin-top: 5px;
}
.step-Wrapse ul {
	padding: 10px 20px 50px;
}
.step-Topse > p {
	padding: 25px 15px;
	font-size: 13px;
	text-align: center;
}
.right-Menuse .binny-Mosco {
	padding:50px;
}
.step-Topse > h3 {
	font-size: 15px;
	padding: 25px 21px;
	font-weight: 500;
}
.step-Wrapse {
	padding-bottom: 200px;

}
.step-Topse {
	background: #4c864a;
	color: #fff;
	margin: 0 -20px;
	border-bottom:1px solid #2c2c2c;
	position:relative;
}
.step-Secondse {
	background:#e3ede2;
	padding: 15px;
	margin-top:15px;
	text-align: center;
}
.step-Secondse > p {
	font-size: 13px;
	line-height: 17px;
}

.tab-Rmainse #myTab3 li > button {
	font-size: 0;
	border: 0;
	display:block;
	padding:0;
}
.input-Grupse > label {
	font-size: 16px;
	line-height:17px;
	font-weight: 400;
	color: #2c2c2c;
	margin-bottom: 10px;
	position: relative;/*padding-left: 15px;*/
}
.input-Grupse > label > br, .step-Secondse > h3 > br {
	display: none;
}
/*.input-Grupse > label > span {
	position: absolute;
	left: 0;
	top: 0px;
}*/
.input-Grupse {
	padding-top: 40px;
}
.input-Grupse .custom-select {
	background:#e3ede2 url(./assets/images/arrow.png) no-repeat right 15px center;
	box-shadow: none;
}
.new-Msg {
	background: #e3ede2;
	border: 0;
	border-radius: 0;
	box-shadow: none;
	height: 45px;
	text-align: center;
	font-size: 13px;
	color: #919191;
}
.top-Uptxt > h3, .top-Uptxt > p, .input-Grupse > p, .step-Secondse > h3 {
	font-size: 15px;
	font-weight: 400;
	color: #919191;
	line-height:17px;
}
.input-Grupse > p {
	font-size: 14px;
	line-height:16px;
	font-weight:300;
	padding-bottom:15px;
}
.top-Uptxt > h3 span {
	color: #4c864a;
}
.top-Uptxt > p {
	font-weight: 300;
}
.upld-Wrapse {
	position: relative;
	text-align: center;
	height: 140px;
	background: #e3ede2;
	border: 1px dotted #437542;
}
.top-Uptxtse {
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	padding: 15px;
	display: flex;
	align-items: center;
	justify-content: center;
}
.file-Upld {
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	padding: 15px;
	height: 100%;
	z-index: 99;
}
.fileupload.new-Msg {
	background: none;
}
.btn-file.neFs, .neFs {
	padding: 0;
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	background: none;
	border: 0;
}
.file-Upld.fileupload-exists {
	background: #e3ede2;
}
.file-Upld.fileupload-exists .btn-file.neFs, .file-Upld.fileupload-exists .neFs {
	display: flex;
	justify-content: space-between;
	padding: 15px 10px;
}
.p-upld {
	position: relative;
	height: 15px;
	width: 15px;
	margin: 0 auto;
	overflow: hidden;
	margin-top: 4px;
}
.fileupload-preview {
	text-align: left;
	font-size: 15px;
	font-weight: 500;
}
.hd-Upse {
	display:none;
}
.file-Upld.fileupload-exists .hd-Upse {
	display:flex;
}
.left-Iupse {
	padding-bottom: 15px;
}
.casImg {
	text-align: left;
}
.topRdt .left-Iupse > h3 {
	text-align: right;
}
.delSe .close.fileupload-exists, .fileupload-exists > i {
	padding: 0;
	color: #2c2c2c;
	text-shadow: none;
	opacity: 1;
	border-radius: 0;
	font-size: 14px;
	line-height: 22px;
	position:relative;
	z-index:99;
}
.btn-file.neFs .row {
	width: 100%;
}
.row.topRdt1.no-gutters.align-items-center {
	max-width: 60px;
	margin: 0 auto;
}
.neFs2 {
	font-size: 0;
	border: 0;
	position: absolute;
	left: 0;
	right: 0;
	top:0;
	bottom: 0;
	opacity: 0;
	z-index: 999;
	cursor:pointer;
}
.left_Imgup {
	position: relative;
	padding-left: 30px;
}
.casImg.lftSe {
	position: absolute;
	left: 0;
	top: 0;
}
.delSe {
	position: absolute;
	top: -10px;
	right: 0;
	left:0;
}
.fileupload-exists > i.edit {
	position: absolute;
	left: 0;
	right: 0;
	top: -2px;
	bottom: 0;
	z-index: 2;
}
.casImg {
	display: none;
}
.file-Upld.fileupload-exists .casImg {
	display: block;
}
.file-Upld.fileupload-exists .row.casImg {
	display: flex;
	position: absolute;
	left: 0;
	right: 0;
	top: -15px;
	padding: 15px 10px;
}
.left-Iupse > h3 {
	font-size: 15px;
	text-align: left;
	color: #2c2c2c;
}
.input-Grupse a {
	border-radius: 5px;
	padding: 20px 20px 20px 31px;
	font-size: 14px;
	font-weight: 400;
	text-transform: inherit;
	line-height: 14px;
}
.bar-Codese {
	text-align: center;
	padding: 30px 0;
	min-height: 350px;
	display: flex;
	align-items: center;
	justify-content: center;
}
.input-Grupse2 > a {
	border-radius: 4px;
	text-transform: inherit;
	font-size: 18px;
	line-height: 18px;
	font-weight: 500;
	padding: 22px 15px;
}
.step-Secondse > h3 {
	font-size: 16px;
	color: #2c2c2c;
	padding-bottom: 10px;
	line-height: 17px;
}
.pop-Step1 > h3 {
	text-transform: uppercase;
	font-weight: 500;
}
.pop-Step1 > h3 > span {
	color:#4c864a;
}
.sek-Wrapse {
	padding:150px 0 0;
	border-bottom: 1px solid #2c2c2c;
	text-align: center;
}
.sek-Ryttxt {
	position: absolute;
	right: 0;
	top: 0;
	bottom: 0;
	width: 175px;
	text-align: left;
	font-size: 26px;
	color: #4c864a;
	display: flex;
	align-items: center;
}
.inputSek .form-control::placeholder {
	color: #4c864a;
	opacity: 1;
}
.inputSek {
	position: relative;
	height: 50px;
}
.sek-Wrapse .new-Msg.SekType {
	background: none;
	font-size: 26px; 
	height: 100%;
	color: #4c864a;
	padding: 8px 180px 8px 8px;
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	text-align: right;
}
/*@media screen and (-webkit-min-device-pixel-ratio:0) {
 
}*/
.sek-Wrapse .custom-select.new-Msg > option {
	font-size: 14px;
	color: #919191;
}
.custom-select:focus {
	border:0;
	box-shadow:none;
}
.utang-Popse > a {
	font-size: 20px;
	font-weight: 400;
	border-radius: 4px;
	padding: 22px 15px 22px 60px;
	line-height: 20px;
}
.utang-Popse {
	padding: 30px 0;
}
.brite-Lgsec {
	text-align: center;
}
.step-Secondse.rht-Second2 > p {
	font-weight: 400;
}
.right-Menuse4 .binny-Mosco {
	padding: 20px 0;
	min-height: 500px;
	display: flex;
	align-items: center;
	justify-content: center;
}
.input-Grupse3 > ul {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding-top: 30px;
	border-top: 1px solid #e3ede2;
}
.input-Grupse3 > ul > li > a {
	font-size: 16px;
	color: #11115e;
	font-weight: 500;
	-webkit-transition: all 400ms ease-in-out 0s;
	-moz-transition: all 400ms ease-in-out 0s;
	-ms-transition: all 400ms ease-in-out 0s;
	-o-transition: all 400ms ease-in-out 0s;
	transition: all 400ms ease-in-out 0s;
}
.input-Grupse3 > ul > li > a:hover {
	color:#4c864a
}
.input-Grpsec {
	text-align: center;
	background: #e3ede2;
	padding: 5px 15px;
	min-height: 45px;
	display: flex;
	align-items: center;
	justify-content: center;
}
.input-Grpsec > p, .sek-Wrapse > p {
	font-size: 16px;
	font-weight: 400;
	color: #4c864a;
}
.sek-Wrapse > p {
	font-size: 22px;
}
.input-Grupse .custom-select option {
	background: none;
	border: 0;
	color: #919191;
	font-size: 14px;
	display: block;
}
.input-Grupse .custom-select option:hover {
	background:#dde2dd;
}
.input-Grupse.top-Skwrap {
	padding: 0;
	position: relative;
}
.input-Grupse.top-Skwrap #nav-tabContent {
	position: absolute;
	left: 0;
	top: 0;
	bottom: 0;
	width: 90px;
	display: flex;
	align-items: center;
	justify-content: center;
}
.open_wrp1 .input-Grupse a.gray-Btn {
	background: #4c864a;
	border-bottom: 2px solid #2c332c;
	border-right: 2px solid #2c332c;
	color: #fff;
}
.open_wrp1 .input-Grupse a.gray-Btn:hover {
	background: #437542;
}
/*****************
//steps css //
****************/
.logo-Main > img {
	max-width: 150px;
}
.not-Ficse {
	position: absolute;
	top: -10px;
	right: -10px;
	background: #ffdb30;
	width: 24px;
	height: 24px;
	border-radius: 24px;
	border: 1px solid #2c332c;
	display: flex;
	align-items: center;
	justify-content: center;
	color: #4c864a;
	font-size: 12px;
	font-weight: 400;
}
.main-Menuse ul > li > a i {
	padding-left: 3px;
	font-style: normal;
}
.main-Menuse ul > li > a {
	background: #4c864a;
	border-bottom: 2px solid #2c332c;
	border-right: 2px solid #2c332c;
	color: #fff;
	font-size: 14px;
	line-height:14px;
	font-weight: 500;
	position:relative;
	border-radius:10px;
	display: flex;
	align-items: center;
	padding:10px;
	-webkit-transition: all 400ms ease-in-out 0s;
	-moz-transition: all 400ms ease-in-out 0s;
	-ms-transition: all 400ms ease-in-out 0s;
	-o-transition: all 400ms ease-in-out 0s;
	transition: all 400ms ease-in-out 0s;
}
.dropdown-toggle::after {
 display: none;
}
.main-Menuse ul > li .dropdown-menu {
	left: auto;
	right: 0;
	background: #4c864a;
	border: 0;
	padding: 0 15px;
	box-shadow: 1px 2px 0px 1px #2c2c2c;
	margin-top: 8px;
}
.main-Menuse ul > li .dropdown-menu > a {
	color: #fff;
	font-size: 14px;
	font-weight: 400;
	padding: 12px 0;
	line-height: 14px;
	text-align:center;
	position:relative;
	-webkit-transition: all 400ms ease-in-out 0s;
	-moz-transition: all 400ms ease-in-out 0s;
	-ms-transition: all 400ms ease-in-out 0s;
	-o-transition: all 400ms ease-in-out 0s;
	transition: all 400ms ease-in-out 0s;
}
.main-Menuse ul > li .dropdown-menu > a:hover {
	background:none;
	color:#ffdb31;
}
.main-Menuse ul > li .dropdown-menu > a + a {
	border-top: 1px solid #58aa55;
}
.main-Menuse ul > li .dropdown-menu > a:after {
	background:#41763f;
	content:"";
	position:absolute;
	left:0;
	right:0;
	bottom:0;
	height:1px;
}
.main-Menuse ul > li > a > img {
	width: 100%;
}
.main-Menuse ul > li + li {
	margin-left:15px;
}
.main-Menuse ul > li + li > a > img {
	margin-left:10px;
}
.main-Menuse ul > li > a:hover {
	color:#ffdb31;
}
.main-Menuse ul {
	display: flex;
	align-items: center;
	justify-content: flex-end;
}
.reg-Btn {
	background: #ffc913;
	border-radius: 3px;
	width: 100px;
	text-align: center;
	line-height: 14px;
	border-bottom: 2px solid #cfa20a;
	box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.15);
	-webkit-transition: all 400ms ease-in-out 0s;
	-moz-transition: all 400ms ease-in-out 0s;
	-ms-transition: all 400ms ease-in-out 0s;
	-o-transition: all 400ms ease-in-out 0s;
	transition: all 400ms ease-in-out 0s;
}
.reg-Btn:hover {
	opacity:0.9;
}
.main-Menuse ul > li > a.reg-Btn {
	padding: 8px 5px 6px;
}
.main-Menuse ul > li + li > a.reg-Btn {
	margin-left:20px;
}
.banner-Mainse {
	background:#58aa55;
	color: #fff;
	position:relative;
	z-index:2;
	text-align:center;
}
.inner-Banse {
	padding: 110px 0 170px;
	position: relative;
	z-index: 99;
}
.ban-Botse {
	position: absolute;
	bottom: 30px;
	left: -150px;
}
.ban-Txtwrap > h1 {
	font-size: 36px;
	line-height: 36px;
	font-weight: 500;
	padding-top:20px;
	text-transform: uppercase;
}
.ban-Txtwrap > h1 > span {
	display:block;
	font-size: 60px;
	line-height: 61px;
	font-weight: bold;
}
.ban-Txtwrap > h1 > span {
	color:#2c2c2c;
}
.ban-Txtwrap > h4 {
	font-size: 18px;
	line-height: 24px;
	padding-top: 30px;
	font-weight: 500;
}
.ban-Txtwrap > h4 > span {
	color:#ffdb31;
}
.ban-Txtwrap > p {
	font-size: 16px;
	line-height: 22px;
	padding-top:10px;
}
.play-Btnse {
	padding-top: 40px;
}
.play-Btnse > a::after {
 display: none;
}
.ban-Txtwrap .play-Btnse > a {
	font-weight: 500;
	font-size: 14px;
	border-bottom: 3px solid #2c332c;
	border-right: 3px solid #2c332c;
	padding: 15px 10px 15px 60px;
	line-height: 14px;
}
.ban-Txtwrap .play-Btnse > a > img {
	position: absolute;
	left: 15px;
	top: 13px;
}
.play-Btnse > a > span {
	font-size: 11px;
	font-weight: 400;
	display: block;
}
.ban-Txtwrap .play-Btnse > a + a {
	margin-left: 10px;
}
.title-Betse {
	text-align:center;
}
.title-Betse > h2 {
	font-size: 36px;
	padding-bottom: 15px;
	position: relative;
	font-weight:600;
	color: #2c2c2c;
}
.title-Betse > h2:after {
	background:#757575;
	content:"";
	position:absolute;
	left:0;
	right:0;
	bottom:0;
	width:100px;
	height:3px;
	border-radius:3px;
	margin:0 auto;
}
.title-Betse > h2::before {
 content: "";
 background: #58af55;
 position: absolute;
 bottom: -6px;
 width: 15px;
 height: 15px;
 z-index: 15;
 margin: 0 auto;
 right: 0;
 -webkit-animation: moveright 3s linear infinite;
 -moz-animation: moveright 3s linear infinite;
 -o-animation: moveright 3s linear infinite;
 border-radius: 30px;
 border: 2px solid rgba(255, 255, 255, 0.5);
 box-shadow: 0 0px 9px 2px rgba(0, 0, 0, 0.1);
}
.title-Betse > p {
	font-size: 16px;
	padding: 20px 0px 40px;
	line-height: 20px;
}
.title-Betse > p > span {
	font-weight:500;
}
.primary-Gbtn {
	background: #65c82d;
	color: #fff;
	font-size: 16px;
	line-height: 16px;
	font-weight: 600;
	text-transform: uppercase;
	padding:18px 10px 16px;
	border-bottom: 2px solid #5cb629;
	position: relative;
	z-index: 2;
	overflow: hidden;
	text-align: center;
	display: inline-block;
	max-width:100%;
	border-radius:10px;
	box-shadow: 0 3px 3px rgba(0, 0, 0, 0.15);
	-webkit-transition: all 400ms ease-in-out 0s;
	-moz-transition: all 400ms ease-in-out 0s;
	-ms-transition: all 400ms ease-in-out 0s;
	-o-transition: all 400ms ease-in-out 0s;
	transition: all 400ms ease-in-out 0s;
}
.primary-Gbtn > i {
	position: absolute;
	left: 15px;
	top: 50%;
	margin-top: -15px;
}
.primary-Gbtn > span {
	display: block;
	font-size: 11px;
	font-weight: 400;
	text-transform: initial;
	padding-top: 3px;
	line-height: normal;
}
.primary-Gbtn::after {
 background: rgba(255, 255, 255, 0.15);
 content: "";
 position: absolute;
 left: 0;
 right: 0;
 bottom: 0;
 height: 0;
 z-index: -1;
 -webkit-transition: all 400ms ease-in-out 0s;
 -moz-transition: all 400ms ease-in-out 0s;
 -ms-transition: all 400ms ease-in-out 0s;
 -o-transition: all 400ms ease-in-out 0s;
 transition: all 400ms ease-in-out 0s;
 transition-timing-function: cubic-bezier(0.1, 0.7, 1.0, 0.1);
}
.primary-Gbtn:hover:after {
	height: 100%;
}
.primary-Gbtn:hover {
	background: #43b422;
	color: #fff;
}
.large-Btn {
	width: 380px;
}
.small-Btn {
	width: 230px;
}
.radius-Gbtn {
	border-radius:100px;
}
.orange-Btn {
	background: #ffc913;
	border-bottom: 2px solid #e3ad00;
	color: #2c2c2c;
}
.orange-Btn:hover {
	background: #ffc913;
	color: #2c2c2c;
}
.green-Btn {
	background: #5ad037;
	border-bottom: 2px solid #4ea932;
	color: #fff;
}
.dark-Green {
	background: #4c864a;
	border-bottom: 2px solid #2c332c;
	border-right: 2px solid #2c332c;
	color: #fff;
}
.dark-Green:hover {
	background: #437542;
}
.gray-Btn {
	background: #b2b6b2;
	border-bottom: 2px solid #2c2c2c;
	border-right: 2px solid #2c2c2c;
	color: #2c2c2c;
}
.gray-Btn:hover {
	background: #1c1c1c;
}
.dark-Red {
	background: #b14344;
	border-bottom: 2px solid #2c2c2c;
	border-right: 2px solid #2c2c2c;
	color: #fff;
}
.dark-Red:hover {
	background: #813a3a;
}
.full-Width {
	display: inline-block;
	width: 100%;
	max-width: 100%;
}
.mid-Mainsec {
	background: #e3ede2;
	text-align: center;
	position:relative;
}
.ratingSec {
	max-width: 480px;
	margin: 0 auto;
	padding-top: 40px;
}
.rat-Leftse > h3 {
	font-size: 14px;
	font-weight: 500;
}
.rat-Midse ul {
	display: flex;
	align-items: center;
	justify-content: center;
}
.rat-Midse ul > li {
	padding:0 2px;
}
.rat-Midse ul > li > span {
	width: 30px;
	height: 30px;
	background: #4c864a;
	display: flex;
	align-items: center;
	justify-content: center;
	border: 1px solid #3A7E38;
	position:relative;
	z-index:4;
}
.halfBg::after {
 background: #dcdce6;
 content: "";
 position: absolute;
 top: 0;
 right: 0;
 bottom: 0;
 width: 50%;
 z-index: -1;
}
.working-Mainse {
	padding: 60px 0 45px;
}
.working-Mainse .row {
	margin:0 -8px;
}
.working-Mainse .col-lg-12 {
	padding:0 8px;
}
.inner-Pwayse .slick-slide {
	padding: 0 !important;
}
.slick-slider {
	margin: inherit;
	width: 100% !important;
}
 
.play-Rmain {
	margin: 60px auto 15px;
	padding: 30px 15px;
	background: #fff;
	box-shadow: 0 3px 5px 1px rgba(0, 0, 0, 0.095);
	border-radius: 3px;
	max-width:266px;
	position: relative;
	color: #fff;
	border: 1px solid #d8d8d8;
}
.ply-Iconse > img {
	display: inline-block;
}
.countSe {
	background: #fff;
	width: 50px;
	height: 45px;
	color: #58aa55;
	font-size: 22px;
	line-height:22px;
	font-weight: 500;
	display: flex;
	align-items: center;
	justify-content: center;
	position: absolute;
	left: -5px;
	top: -5px;
	border-width: 1px 5px 5px 1px;
	border-style: solid;
	border-color: #e3ede2;
}
.countSe::after, .countSe:before {
 content: "";
 position: absolute;
 left: 0;
 right: 0;
 top: 0;
 bottom: 0;
 border: 1px solid #d8d8d8;
}
.countSe::before {
 border-width: 0 1px 1px 0;
 right: -5px;
 bottom: -5px;
 left: 5px;
 top: 5px;
}
.ply-Txtsec {
	padding-top: 20px;
}
.ply-Txtsec > h3 {
	font-size: 24px;
	font-weight: 500;
	padding-bottom: 10px;
	color: #2c2c2c;
	line-height: 25px;
}
.ply-Txtsec > p {
	font-size: 15px;
	line-height: 18px;
	font-weight: 300;
	color:#2c2c2c;
}
.tab-Rmainse #myTab3 {
	padding-bottom: 30px;
}
/*.tab-Rmainse #myTab3 li.ic-1 > a > span {
	margin-top: -1px;
	margin-bottom: 4px;
}
.tab-Rmainse #myTab3 li.ic-2 > a > span {
	margin-top: -1px;
	margin-bottom: 4px;
}
.tab-Rmainse #myTab3 li.ic-3 > a > span {
	margin-top: -1px;
	margin-bottom: 4px;
}
.tab-Rmainse #myTab3 li.ic-4 > a > span {
	margin-top: 2px;
	margin-bottom: 1px;
}
.tab-Rmainse #myTab3 li.ic-5 > a > span {
	margin-top: -1px;
	margin-bottom: 4px;
}
.tab-Rmainse #myTab3 li.ic-6 > a > span {
	margin-top: -4px;
	margin-bottom: 7px;
}
.tab-Rmainse #myTab3 li.ic-7 > a > span {
	margin-top: -1px;
	margin-bottom: 4px;
}*/
.tab-Rmainse #myTab3 li > a > span > img {
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	-webkit-transition: all 400ms ease-in-out 0s;
	-moz-transition: all 400ms ease-in-out 0s;
	-ms-transition: all 400ms ease-in-out 0s;
	-o-transition: all 400ms ease-in-out 0s;
	transition: all 400ms ease-in-out 0s;
}
.tab-Rmainse #myTab3 li > a > span {
	position: relative;
	width: 35px;
	height: 35px;
	margin-right:10px;
}
 @media screen and (min-width:1200px) {
.tab-Rmainse #myTab3 li.nav-item.slick-slide {
 width: 156px !important;
}
}
.tab-Rmainse #myTab3 li > a {
	color: #2c2c2c;
	font-size: 14px;
	font-weight: 500;
	padding:8px 5px;
	border-radius: 10px;
	line-height: 14px;
	margin:0 1px;
	width: 156px;
	-webkit-transition: all 400ms ease-in-out 0s;
	-moz-transition: all 400ms ease-in-out 0s;
	-ms-transition: all 400ms ease-in-out 0s;
	-o-transition: all 400ms ease-in-out 0s;
	transition: all 400ms ease-in-out 0s;
	display: flex;
	align-items: center;
	justify-content: center;
}
.tab-Rmainse #myTab3 li > a.active {
	background:#58af55;
	color:#fff;
}
.tab-Rmainse #myTab3 li > a.active span > img.hover {
	opacity:1;
}
.tab-Rmainse #myTab3 li > a.active span > img.hover-Out {
	opacity:0;
}
.tab-Rmainse #myTab3 li > a > span > img.hover {
	opacity:0;
}
.tab-Rmainse #myTab3 li > a > span > img.hover-Out {
	opacity:1;
}
/*******************
//Toplist//
***************/
.rat-Bg-1 {
	background:#000e2f;
	}
	.rat-Bg-2 {
	background:#0b582e;
	}
	.rat-Bg-3 {
	background:#441361;
	}
	.rat-Bg-4 {
	background:#000000;
	}
.load-Morese {
	padding-top: 50px;
}
.tpbox-Wrapse + .tpbox-Wrapse {
	margin-top: 15px;
}
.top-Listse {
	background: #ffff; 
	overflow: hidden;
	box-shadow: 0 0 6px 1px rgba(0, 0, 0, 0.09);
	min-height: 120px;
	position: relative;
}
.id-Boxse {
	position: absolute;
	left: 0;
	top: 0;
	width: 60px;
	height: 60px;
	z-index: 1;
}
.logo-Topse > a > img {
	height: 60px;
	width:auto;
}
.top-Listxt {
	padding: 15px;
}
.bonus-Boxse > h3, .spin-Boxse > h3 {
	font-size: 16px;
	font-weight: bold;
	line-height: 17px;
	color: #11778d;
}
.bonus-Boxse > p, .spin-Boxse > p {
	font-size: 13px;
	color: #374a54;
	line-height: 13px;
	font-weight: 500;
}
.spin-Boxse > h3 {
	color:#43b422;
}
.ratin-Boxse > ul {
	display: flex;
	align-items: center;
	justify-content: center;
}
.ratin-Boxse {
	position: absolute;
	left: 0;
	right: 0;
	bottom: 0;
	padding: 9px 10px;
}
.ratin-Boxse > ul > li {
	line-height: 17px;
}
.ratin-Boxse > ul > li > a {
	font-size: 17px;
	color: #fff;
	line-height: 16px;
	margin: 0 2px;
}
.ratin-Boxse > p {
	font-size: 13px;
	line-height: 13px;
	color: #374a54;
}
.info-Boxse > ul > li {
	font-size: 12px;
	line-height: 17px;
	font-weight: 500;
	text-align: left;
	padding-left: 20px;
	position: relative;
	color:#333;
}
.info-Boxse > ul > li:before {
	content:"f058";
	position:absolute;
}
 .info-Boxse > ul > li::before {
	/*content: "\f058";*/
	content: "";
 position: absolute;
 font-family: fontawesome;
 left: 0;
 top: 7px;
 color: #43b422;
 width: 11px;
 height: 6px;
 border-width: 0 0 2px 3px;
 border-style: solid;
 transform: rotate(-45deg);
}
.info-Boxse > ul > li.flg-icon::before {
 background: url(./assets/images/flg-icon.png) no-repeat center center/auto;
 width: 10px;
 height: 10px;
 border-radius: 10px;
 border: 0;
 transform: none;
 background-size: 10px;
}
.review-Boxse .primary-Btn {
	box-shadow: 0 0 4px 1px rgba(0, 0, 0, 0.15);
	border: 0;
	font-size: 14px;
	padding: 15px 10px;
}
.list-Topse {
	line-height:12px;
}
.list-Topse > a {
	font-size: 11px;
	color: #939393;
	text-transform: uppercase;
	line-height: 12px;
	font-weight: 400;
	display: inline-block;
}
.list-Topse > a .show_Ar {
	margin-left: 10px;
	display:inline-block;
}
.list-Topse > a.collapsed .show_Ar {
	transform:rotate(-180deg);
}
.list-Boxse {
	background: #efefef;
	padding: 20px;
	box-shadow: 0 0 6px 1px rgba(0, 0, 0, 0.09);
	border: 1px solid #e5e5e5;
	margin-top: 5px;
	text-align: left;
	border-radius: 3px;
}
.list-Boxtxtse > ul > li {
	font-size: 13px;
	line-height: 17px;
	color: #353535;
	position: relative;
	padding-left: 30px;
}
.list-Boxtxtse > ul > li + li {
	margin-top:10px;
}
.list-Boxtxtse > ul > li:before {
	content: "\f00c ";
	position: absolute;
	left: 0;
	top: 0;
	font-family: fontawesome;
	color: #43b422;
	font-size: inherit;
}
.list-Boxtxtse > ul > li.not-Capable:before {
	content: "\f00d";
	color:#EE2737FF;
}
.title-Box > h3 {
	font-size: 15px;
	padding-bottom: 14px;
	line-height: 16px;
	color:#374a54;
	font-weight: 600;
}
.link-Boxse .primary-Btn.full-Width {
	border: 0;
	box-shadow: 0 0 3px 2px rgba(0, 0, 0, 0.09);
	font-size: 14px;
}
.list-Boxtxtse > p {
	font-size: 13px;
	line-height: 16px;
	font-weight: 600;
	color:#353535;
}
 @media screen and (min-width:991px) {
.top-Listse .col-lg-3 {
 flex: 0 0 20%;
 max-width:100%;
}
.top-Listse .col-lg-9 {
 flex: 0 0 80%;
 max-width:100%;
}
.list-Topse {
 position: absolute;
 left: 0;
 right: 0;
}
.right-Marse {
 padding-left:45px;
}
}
.tpbox-Wrapse {
	position: relative;
	padding-top: 7px;
	z-index:2;
}
.tprib-Mainse {
	position: absolute;
	left: 15px;
	top: 0;
	width: 80px;
	min-height: 22px;
}
.tprib-text {
	position: absolute;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	z-index: 9;
	background: #58af55;
	color: #fff;
	padding: 5px;
	font-size: 11px;
	text-transform: uppercase;
	font-weight: 500;
	line-height: 11px;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 0 0 5px 5px;
	box-shadow: 0 3px 3px 1px rgba(0, 0, 0, 0.095);/*text-shadow: 0 0px 1px #000;*/
}
.tprib-Angse > i {
	position: absolute;
	left: -8px;
	top: -4px;
	font-size: 20px;
	line-height: 20px;
	color: #3b7a38;
	z-index:-1;
	transform: rotate(-45deg);
}
.tprib-Angse > i.ryt-Se {
	left: auto;
	right: -8px;
	transform: rotate(45deg);
}
.red .tprib-text {
	background:#e21a22;
}
.red .tprib-Angse > i {
	color:#8f1015;
}
.yellow .tprib-text {
	background:#ffc913;
	color:#2c2c2c
}
.yellow .tprib-Angse > i {
	color:#a98a05;
}
.box-Ifopad {
	padding-bottom: 10px;
}
.box-Tpwrpse .row {
	margin: 0 -10px;
}
.box-Tpwrpse .col-lg-3 {
	padding: 10px;
}
.spcae-Topse4 {
	padding-top: 5px;
}
.information-Boxse > h3 {
	font-size:19px;
	color: #43b422;
	line-height: 21px;
}
.information-Boxse > p {
	font-size: 13px;
	color: #2c2c2c;
	line-height: 16px;
	padding-top: 10px;
}
.age-Breqsec {
	background: #fcfcfc;
	padding: 12px 15px;
	box-shadow: 0 1px 6px 1px rgba(0, 0, 0, 0.079) inset;
}
.inner-Bpyse .age-Breqsec {
	background: none;
	padding: 10px 0 0;
	box-shadow: none;
	margin:0 -5px;
	text-align:center;
}
.age-Breqsec ul {
	display: flex;
	align-items: center;
	justify-content: center;
}
.age-Breqsec ul > li {
	font-size: 12px;
	line-height: 12px;
	color: #2c2c2c;
	font-weight: 300;
	padding:0 5px;
}
.age-Breqsec ul > li > a {
	color:#4ca0db;
}
.age-Breqsec ul > li + li {
	border-left:1px solid #2c2c2c;
}
.information-Boxse {
	text-align: left;
}
.logo-Topse {
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	padding:34px 15px 70px;
	position: relative;
}
/*******************
//money section//
***************/
.mny-Rtitle > h3 {
	font-size: 24px;
	line-height: normal;
	padding-bottom: 15px;
	color: #2c2c2c;
}
.money-Listse {
	background: #ffff;
	border-radius: 10px;
	overflow: hidden;
	box-shadow: 0 0 6px 1px rgba(0, 0, 0, 0.09);
	position: relative;
}
.money-Listse + .money-Listse {
	margin-top:15px
}
.client-Rdetailse > h3, .client-Rmoney > h3, .date-Rmoneyse > h3 {
	font-size: 13px;
	color: #2c2c2c;
	font-weight: 500;
	text-align: center;
}
.client-Rmoney > h3 {
	text-transform:uppercase;
	color: #43b422;
}
.logo-Rmoneyse {
	min-height: 80px;
	display: flex;
	align-items: center;
	justify-content: center;
}
.mony-Swrapse .slick-list {
	padding: 7px 0 !important;
	margin: 7px 0 10px;
}
.mony-Swrapse::after {
 background: #e3ede2;
 content: "";
 position: absolute;
 left: 0;
 right: 0;
 bottom: 0;
 height:13px;
}

/*******************
//Toplist end//
***************/

/******************
// Toggle//
*********************/
 
.cross, .off-nav > a > span {
	background: #fff;
	display: block;
	height: 6px;
	border-radius: 6px;
	position: absolute;
	transition: background 300ms 400ms ease;
	left: 5px;
	right: 5px;
	top: 50%;
	margin-top: -3px;
}
.cross:after, .cross:before  {
	background: #fff;
	border-radius: 6px;
	content: "";
	position: absolute;
	height: 6px;
	left: 0;
	right: 0;
	top: -12px;
	transition: top 300ms ease 500ms, -webkit-transform 300ms ease 0s;
	transition: top 300ms ease 500ms, transform 300ms ease 0s;
}
.cross:before {
	width:25px;
	top: 12px;
	left:auto;
}
.close_nav .cross, .off-nav > a > .cross {
	background: none
}
.off-nav .cross:after, .off-nav .cross:before,
.close_nav .cross:after, .close_nav .cross:before  {
	background: #58aa55;
	top: 0;
	width:100%;
	left:0;
	transition: top 300ms ease 0s, -webkit-transform 300ms ease 400ms;
	transition: top 300ms ease 0s, transform 300ms ease 400ms;
}
.close_nav .cross:before,.off-nav .cross:before {
	transform: rotate(45deg);
}
.close_nav .cross:after,  .off-nav .cross:after {
	transform: rotate(-45deg);
}
.tog_nav, .off-nav > a {
	width: 50px;
	height: 50px;
	display: inline-block;
	padding:5px;
	justify-content: center;
	position: absolute;
	left:0;
	border-radius:50px;
	z-index: 99;
	top: 0;
	-webkit-transition: all 400ms ease-in-out 0s;
	-moz-transition: all 400ms ease-in-out 0s;
	-ms-transition: all 400ms ease-in-out 0s;
	-o-transition: all 400ms ease-in-out 0s;
	transition: all 400ms ease-in-out 0s;
}
.tog_nav {
	margin-top: -10px;
}
.close_nav .tog_nav {
	background:#fff;
	left:280px;
	z-index:99999999;
	box-shadow: 0 0 6px 1px rgba(0, 0, 0, 0.09);
	margin-top: 15px;
}
.off-nav > a {
	background: #fff;
	left:auto;
	right:0; 
}
/***************
//End Toggle//
****************/ 

.bg-tp-1 {
	background: #ff6600;
}
.bg-tp-2 {
	background: #fad48d;
}
.bg-tp-3 {
	background: #3dbeba;
}
.bg-tp-4 {
	background: #ef4136;
}
.bg-mtp-1 {
	background: #294b99;
}
.bg-mtp-2 {
	background: #000000;
}
.bg-mtp-3 {
	background: #6c05a8;
}
.bg-mtp-4 {
	background: #015536;
}
.bg-mtp-5 {
	background: #006d69;
}
.bg-mtp-6 {
	background: #133154;
}
.bg-mtp-7 {
	background: #202020;
}
.bg-mtp-8 {
	background: #29b5fc;
}
.bg-ctp-1 {
	background: #02133c;
}
.bg-ctp-2 {
	background: #10713c;
}
.bg-ctp-3 {
	background: #5e297d;
}
.bg-ctp-4 {
	background: #171717;
}
/*********************
//review page//
********************/
  
.spcae-Topse3 {
	padding-top: 10px;
}
.pyment-Boxse ul {
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	margin: 0 -3px;
}
.pyment-Boxse ul > li {
	padding: 0 5px;
	max-width: 45px;
}
.reason-Btxtse {
	padding:15px 0;
}
.reason-Btxtse > h3 {
	font-size: 15px;
	padding-left: 45px;
	position: relative;
	line-height: 16px;
	font-weight: 400;
	margin-bottom: 10px;
}
.reason-Btxtse > h3 > span {
	position: absolute;
	left: 0;
	top: 50%;
	margin-top: -15px;
	width:30px;
}
.reason-Btxtse > h3 > i {
	position:absolute;
	left:0;
	color: #58af55;
}
.rdetail-Bodyse .row, .genric-Bwrapse .row {
	margin: 0 -5px;
	width:100%;
}
.rdetail-Bodyse .col-lg-7, .rdetail-Bodyse .col-lg-5, .genric-Bwrapse .col-7, .genric-Bwrapse .col-5, .genric-Bwrapse .col-12 {
	padding: 0 5px;
}
.inner-Bpyse {
	background: #f1f1f1;
	padding: 30px;
	box-shadow: 0 1px 6px 1px rgba(0, 0, 0, 0.15);
}
.inner-Bpyse.tp-Marse {
	text-align: left;
}
.inner-Bpyse.tp-Marse .reason-Btxtse {
	padding-top: 0;
}
.tp-Marse {
	margin-top: 5px;
}
.genric-Bwrapse {
	padding: 10px;
	background: #fff;
	box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.05);
}
.genric-Bwrapse + .genric-Bwrapse {
	margin-top: 10px;
}
.genric-Binfose > p {
	font-size: 14px;
	font-weight: 400;
	line-height: 15px;
	color: #2c2c2c;
}
.genric-Binfose > p > span {
	font-size: 15px;
	font-weight: 500;
	line-height: 16px;
	color: #2c2c2c;
}
.app-Boxse > ul {
	display: flex;
	align-items: flex-start;
	flex-wrap: wrap;
	margin: 0 -5px;
}
.app-Boxse > ul > li {
	padding:0 5px;
}
.app-Boxse > ul > li > a {
	font-size:20px;
	color:#58af55;
}
.ryt-Grnse {
	min-height: 92px;
	display: flex;
	text-align: center;
}
.ryt-Grnse .genric-Binfose > p {
	color: #58af55;
}
.ryt-Grnse .genric-Binfose > p {
	color: #58af55;
	font-size: 15px;
	font-weight: 500;
}
.inner-Bpyse > p {
	font-size: 13px;
	font-weight: 500;
	color: #454545;
	line-height: 14px;
	padding-top: 10px;
	text-align: center;
}
.brand-Btxtse > h3 {
	font-size: 15px;
	font-weight: 500;
	line-height: 16px;
	color: #2c2c2c;
}
.brand-Btxtse > p {
	font-size: 13px;
	color: #454545;
	padding-top: 10px;
}
.brand-Bwrapse {
	background: #f8f8f8;
	padding: 20px 30px 30px;
	box-shadow: 0 1px 6px 1px rgba(0, 0, 0, 0.15);
	margin-top: 10px;
}
.brand-Btxtse {
	margin-top: 10px;
}
.acc-Bottomse > ul > li {
	position: relative;
	padding-left: 25px;
	font-size: 14px;
	line-height: 18px;
	color: #353535;
}
.acc-Bottomse > ul > li + li {
	margin-top: 10px;
}
.acc-Bottomse > ul > li::before {
 content: "\f00c ";
 position: absolute;
 left: 0;
 top: 0;
 font-family: fontawesome;
 color: #43b422;
 font-size: inherit;
}
.play-Boxse .row {
	margin:0 -10px;
}
.play-Boxse .col-lg-6 {
	padding:0 10px;
}
.play-Boxtxt {
	background: #fff;
	border-radius: 3px;
	box-shadow: 0 3px 3px rgba(0, 0, 0, 0.1);
	overflow: hidden;
}
.play-Boxtxt + .play-Boxtxt {
	margin-top: 8px;
}
.play-Boxse {
	padding: 60px 0 80px;
	text-align: left;
}
.play-Header > h3 {
	display: block;
	box-shadow: 0 3px 3px rgba(0, 0, 0, 0.1);
	padding:20px 20px 20px 45px;
	font-size: 14px;
	font-weight: 600;
	cursor: pointer;
	border-radius: 3px;
	position: relative;
	z-index: 5;
	line-height:15px;
}
.play-Header > h3 > span {
	position: absolute;
	left: 15px;
	top: 50%;
	margin-top: -8px;
}
.mny-Rtitle > h3 > br {
	display: none;
}
.play-Header > h3 > br {
	display: none; 
   }
.play-Bodytxt.acc-Bottomse > p {
	font-size: 13px;
	line-height: 20px;
}
.play-Bodytxt.acc-Bottomse > ul > li {
	font-size: 13px;
	line-height: 17px;
}
.play-Bodytxt.acc-Bottomse {
	border: 0;
	padding: 20px;
	background: #f5f5f5;
}
.play-Header > h3 > i {
	position: absolute;
	right: 15px;
	top: 50%;
	margin-top: -7px;
}
.play-Header > h3.collapsed i {
	transform: rotate(180deg);
}
.play-Header > h3.collapsed::after, .play-Header > h3::after {
	content: "+";
	position: absolute;
	right: 15px;
	top: 50%;
	margin-top: -8px;
}
.play-Header > h3:after {
	content:"-";
}
.news-Rmainse {
	padding: 60px 0;
}
.inner-Bnewse {
	text-align: left;
}
.pub-Mainsec {
	display: flex;
	align-items: center;
	justify-content: space-between;
	color: #999;
}
.pub-Leftsec > h4, .pub-Right > h4 {
	font-size: 12px;
	font-weight: 300;
	color: #999;
	line-height: 12px;
}
.news-Wrap {
	background: #fff;
	margin:15px 0;
	overflow:hidden;
	border:1px solid #b8b7b7;
	padding:15px;
	box-shadow: 0 1px 4px 1px rgba(0, 0, 0, 0.095);
}
.news-Topimg > img {
	width: 100%;
}
.nwbox-title {
	padding-top: 15px;
}
.nwbox-title > h3 {
	font-size: 18px;
	line-height: 19px;
	font-weight: 500;
	color: #2c2c2c;
}
.news-Boxtxt > p {
	font-size: 14px;
	line-height: 22px;
	color: #454545;
}
.read-Bnwsec {
	padding-top: 15px;
}
.news-Boxdate {
	padding: 15px 0;
}
.news-Wrap {
	margin: 15px 0;
}
.lay-wrap {
	overflow:hidden;
}
.bot-Layse {
	margin:0 -20px -2px;
}
footer {
	position:relative;
	color:#fff;
}
.top-Footsec {
	background:#015536;
	position: relative;
	padding:40px 0 60px;
	text-align:center;
}
.foot-Toplkse {
	padding: 40px 0;
}
.foot-Toplkse > ul {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-wrap: wrap;
}
.foot-Toplkse > ul > li {
	padding: 0 15px;
	line-height:14px;
}
.foot-Toplkse > ul > li > a {
	font-size: 18px;
	color:#fff;
	text-transform: uppercase;
	-webkit-transition: all 400ms ease-in-out 0s;
	-moz-transition: all 400ms ease-in-out 0s;
	-ms-transition: all 400ms ease-in-out 0s;
	-o-transition: all 400ms ease-in-out 0s;
	transition: all 400ms ease-in-out 0s;
}
.foot-Toplkse > ul.bottom-Rnav {
	padding-top:15px;
}
.foot-Toplkse > ul.bottom-Rnav > li > a {
	font-size: 14px;
}
.foot-Toplkse > ul > li > a:hover, .foot-Robse ul > li > a:hover {
	color: #fee13b;
}
.footer-Logosec {
	text-align: center;
}
.inner-Topfoot {
	position: relative;
	z-index: 99;
	margin-top: -50px;
}
.foot-Wrapse {
	padding-top: 30px;
}
.foot-Wraptx {
	text-align: left;
	max-width: 350px;
	width: 100%;
	display: inline-block;
}
.foot-Wrapse > ul > li > a:hover {
	margin-left:5px;
}
.fot-Rtitlese > h3 {
	font-size: 16px;
	position: relative;
	padding-bottom: 15px;
	border-bottom: 1px solid #ece7f4;
	display: inline-block;
	font-weight:500;
}
.fot-Rtitlese > h3::after {
 background: #fee13b;
 content: "";
 position: absolute;
 left: 0;
 right: 0;
 bottom: -1px;
 height: 3px;
 width: 60px;
}
.foot-Robse ul {
	padding-top: 20px;
}
.foot-Robse ul > li > a {
	font-size: 14px;
	font-weight: 300;
	color: #fff;
	padding-left: 20px;
	position: relative;
	-webkit-transition: all 400ms ease-in-out 0s;
	-moz-transition: all 400ms ease-in-out 0s;
	-ms-transition: all 400ms ease-in-out 0s;
	-o-transition: all 400ms ease-in-out 0s;
	transition: all 400ms ease-in-out 0s;
}
.foot-Robse ul > li > a > i {
	color:#fee13b;
	position:absolute;
	left:0;
	top:1px;
}
.foot-Robse ul > li > a:hover {
	margin-left:5px;
}
.bottom-Footer {
	padding: 20px 0;
	text-align: center;
	background:#033a26;
}
.fot-Rlogose {
	display: inline-block;
}
.fot-Rlogose ul {
	display: flex;
	align-items: center;
	justify-content: center;
	padding-top: 20px;
}
.fot-Rlogose ul > li {
	padding: 0 20px;
}
.fot-Rlogose ul > li + li {
	padding-top: 5px;
}
.fot-Rwrapse {
	text-align: center;
}
.inner-Botfoot > p {
	font-size: 12px;
}
.foot-Wraptx > p {
	font-size: 14px;
	font-weight: 300;
	line-height: 21px;
	padding-top: 15px;
}
.fot-Botsec ul {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-wrap: wrap;
}
.fot-Botsec ul > li {
	padding: 0 10px;
	color: #fff;
	font-size: 13px;
	text-align:left;
	font-weight: 300;
	position:relative;
}
.fot-Botsec ul > li.midse {
	padding-left:40px;
}
.fot-Botsec ul > li + li {
	border-left:1px solid #fff;
}
.fot-Botsec ul > li > a {
	font-size: 14px;
	color: #fee13b;
	line-height: normal;
}
.fot-Botsec ul > li > a > i > img {
	max-width: 30px;
}
.fot-Botsec ul > li > span {
	width: 25px;
	height: 25px;
	border-radius: 25px;
	background: #fff;
	display: flex;
	align-items: center;
	justify-content: center;
	color: #2c2c2c;
	font-size: 11px;
	font-weight: 500;
	position: absolute;
	left: 10px;
	top: -1px;
}
.fot-Botsec ul.mob-Stes {
	display: inline-block;
	padding-bottom:15px;
}
.fot-Botsec ul.mob-Stes > li {
	padding-left: 30px;
	padding-right: 0;
}
.footer-Logosec > a > img {
	max-width: 300px;
	width: 100%;
}
.modal-Wrapse {
	max-width: 680px;
	width: 100%; 
}
.modal-content {
	background: none;
	box-shadow: none;
	border: 0;
}
.right-Logse {
	background: #4c864a;
}
.left-Logse {
	padding: 30px;
	text-align: center;
}
.left-Logse > p {
	font-size: 12px;
	line-height: 14px;
	color: #353535;
	padding: 25px 0;
}
.left-Logse > h3 {
	font-size: 30px;
	line-height: 31px;
	color: #353535;
}
.lg-Btnse {
	padding-top: 20px;
}
.inputGrp {
	margin-top: 30px;
	position: relative;
}
.lg-Btnse > a {
	padding: 15px 15px 15px 60px;
	border-radius: 4px;
	font-size: 18px;
	font-weight: 500;
}
.inputGrp {
	margin-top: 30px;
	position: relative;
	min-height:50px;
	border: 1px solid #bdbdbd;
	border-radius: 4px;
	background:#fff;
}
.form-control.log-Frm {
	position: absolute;
	border: 0;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	height: 100%;
	background: none;
	font-size: 12px;
	color: #919191;
	padding: 8px 10px 8px 35px;
}
.env {
	position: absolute;
	left: 8px;
	top: 15px;
	font-size: 16px;
	color: #495057;
}
.right-Logse {
	background: #4c864a;
	padding: 30px;
	text-align: center;
	color: #fff;
}
.right-Logse > h3 {
	font-size: 18px;
	line-height: 19px;
	font-weight: 500;
}
.binny-Mose {
	padding: 20px 15px;
}
.right-Logse > p {
	font-size: 12px;
	line-height: 14px;
}
.frgtSec > p {
	font-size: 13px;
	color:#2c2c2c;
}
.frgtSec > p > a {
	color: #568d55;
}
.close-Modse > a {
	position: absolute;
	right: -10px;
	top: -10px;
	background: #fff;
	z-index: 999;
	width: 50px;
	height: 50px;
	border-radius: 50%;
	display: flex;
	color: #58aa55;
	align-items: center;
	justify-content: center;
	font-size: 53px;
	padding:0 0 3px 3px;
	box-shadow: 0px 1px 2px #2c2c2c;
	transform:rotate(45deg);
	-webkit-transition: all 400ms ease-in-out 0s;
	-moz-transition: all 400ms ease-in-out 0s;
	-ms-transition: all 400ms ease-in-out 0s;
	-o-transition: all 400ms ease-in-out 0s;
	transition: all 400ms ease-in-out 0s; 
}
.login-Wrapse {
	background: #e3ede2;
	border-radius: 15px;
	box-shadow: 0 3px 5px 2px rgba(0, 0, 0, 0.15);
	overflow: hidden; 
	-webkit-transition: all 400ms ease-in-out 0s;
	-moz-transition: all 400ms ease-in-out 0s;
	-ms-transition: all 400ms ease-in-out 0s;
	-o-transition: all 400ms ease-in-out 0s;
	transition: all 400ms ease-in-out 0s;
}
.login-Wrapse.sign-Wrapse .left-Logse > p {
	padding-bottom: 0;
}
.lab-Inpt > h3 {
	font-size: 15px;
	font-weight: 500;
	color: #2c2c2c;
	padding-left: 1px;
}
.login-Wrapse.sign-Wrapse .inputGrp {
	margin-top: 5px;
}
.lab-Inpt {
	text-align: left;
	padding-top: 20px;
}
.modal-Wrapse {
	max-width: 750px;
	width: 100%;
	margin: 100px auto; 
	position: relative;
}
.frgtSec {
	padding-top: 15px;
}
  
.chk-Bxse {
	padding-top: 5px;
}
.chk-Bxse .btn {
	padding: 0;
	font-size: 13px;
	display: block;
	text-align: left;
	padding-left: 20px;
	color: #919191;
	position: relative;
	box-shadow:none;
}
.chk-Bxse .btn > a {
	color:#4c864a;
}
.chk-Bxse .btn:before, .chk-Bxse .btn.active::after {
 content:"";
 width:10px;
 height:10px;
 border-radius:2px;
 border:1px solid #999;
 position:absolute;
 left:0;
 top:1px;
}
.chk-Bxse .btn::before, .chk-Bxse .btn.active::after {
 content: "";
 width: 12px;
 height: 12px;
 border-radius: 2px;
 border: 1px solid #999;
 position: absolute;
 left: 0;
 top: 4px;
}
.chk-Bxse .btn.active::after {
 border: 1px solid #4c864a;
}
.chk-Bxse .btn-group-toggle {
	position: relative;
}
.chk-Bxse .btn.active::before {
 width: 9px;
 height: 5px;
 border-radius: 2px;
 border-left: 2px solid #4c864a;
 border-bottom: 2px solid #4c864a;
 position: absolute;
 left: 2px;
 top: 7px;
 color: #4c864a;
 border-top: 0;
 border-right: 0;
 transform: rotate(-45deg);
}
.sign_upse .row {
	margin:0 -3px;
}
.sign_upse .col-lg-7, .sign_upse .col-lg-5, .sign_upse .col-4 {
	padding:0 3px;
}
.row.right_datse .form-control.log-Frm {
	padding: 3px;
	text-align: center;
}
.top-Listxt, .top-Listxt .row {
	height: auto;
}
.tprib-Mainse::after {
	content: none;
}
/**********************
//select menu and uploading file csss//
*************************/
.clearfix {
*zoom:1;
}
.clearfix:before, .clearfix:after {
	display:table;
	content:"";
	line-height:0;
}
.clearfix:after {
	clear:both;
}
.hide-text {
	font:0/0 a;
	color:transparent;
	text-shadow:none;
	background-color:transparent;
	border:0;
}
.input-block-level {
	display:block;
	width:100%;
	min-height:30px;
	-webkit-box-sizing:border-box;
	-moz-box-sizing:border-box;
	box-sizing:border-box;
}
.btn-file {
	overflow:hidden;
	position:relative;
	vertical-align:middle;
}
.btn-file>input {
	position:absolute;
	top:0;
	right:0;
	margin:0;
	opacity:0;
	filter:alpha(opacity=0);
	transform:translate(-300px, 0) scale(4);
	font-size:23px;
	direction:ltr;
	cursor:pointer;
}
.fileupload {
	margin-bottom:9px;
}
.fileupload .uneditable-input {
	display:inline-block;
	margin-bottom:0px;
	vertical-align:middle;
	cursor:text;
}
.fileupload .thumbnail {
	overflow:hidden;
	display:inline-block;
	margin-bottom:5px;
	vertical-align:middle;
	text-align:center;
}
.fileupload .thumbnail>img {
	display:inline-block;
	vertical-align:middle;
	max-height:100%;
}
.fileupload .btn {
	vertical-align:middle;
}
.fileupload-exists .fileupload-new, .fileupload-new .fileupload-exists {
	display:none;
}
.fileupload-inline .fileupload-controls {
	display:inline;
}
.fileupload-new .input-append .btn-file {
	-webkit-border-radius:0 3px 3px 0;
	-moz-border-radius:0 3px 3px 0;
	border-radius:0 3px 3px 0;
}
.thumbnail-borderless .thumbnail {
	border:none;
	padding:0;
	-webkit-border-radius:0;
	-moz-border-radius:0;
	border-radius:0;
	-webkit-box-shadow:none;
	-moz-box-shadow:none;
	box-shadow:none;
}
.fileupload-new.thumbnail-borderless .thumbnail {
	border:1px solid #ddd;
}
.control-group.warning .fileupload .uneditable-input {
	color:#a47e3c;
	border-color:#a47e3c;
}
.control-group.warning .fileupload .fileupload-preview {
	color:#a47e3c;
}
.control-group.warning .fileupload .thumbnail {
	border-color:#a47e3c;
}
.control-group.error .fileupload .uneditable-input {
	color:#b94a48;
	border-color:#b94a48;
}
.control-group.error .fileupload .fileupload-preview {
	color:#b94a48;
}
.control-group.error .fileupload .thumbnail {
	border-color:#b94a48;
}
.control-group.success .fileupload .uneditable-input {
	color:#468847;
	border-color:#468847;
}
.control-group.success .fileupload .fileupload-preview {
	color:#468847;
}
.control-group.success .fileupload .thumbnail {
	border-color:#468847;
}

.casino-Selctmenu #myTab {
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	z-index: 9999;
	width: 100px;
	padding: 0;
}
#mySelect {
	position: absolute;
	border: 0;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	width: 100%;
	padding-left: 170px;
	/* text-align: center; */
	background: none;
	color: #919191;
	font-size: 16px;
}
select#mySelect:focus {
    outline: none;
}
/*.casino-Selctmenu.new-Msg::after {
	content: "";
	position: absolute;
	right: 0;
	top: 0;
	bottom: 0;
	width: 20px;
	background: inherit;
}*/
.casino-Selctmenu #myTab > li > a img {
	width: auto;
	height: 28px;
}
.casino-Selctmenu { 
	position: relative;
	padding-left: 120px; 
}
.casino-Selctmenu #myTab > li > a {
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	opacity: 0;
	padding: 0; 
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 0;
}
.casino-Selctmenu #myTab > li > a.active {
 
	opacity: 1;
}
* Arrows */
.slick-prev, .slick-next {
	padding: 10px;
	height: 35px;
	width: 35px;
	font-size: 13px;
	display: flex;
	border-radius: 3px;
	color: #333;
	overflow: hidden;
	box-shadow: 0 0 4px 1px rgba(0,0,0,0.095);
	z-index: 999;
	position:absolute;
	top:50%;
	margin-top:-23px;
	border:0;
}
.slick-prev:hover,
.slick-prev:focus,
.slick-next:hover,
.slick-next:focus
{ 
    outline: none; 
}
.slick-prev:hover:before,
.slick-prev:focus:before,
.slick-next:hover:before,
.slick-next:focus:before
{
    opacity: 1;
}
.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before
{
    opacity: .25;
}

.slick-prev:before,
.slick-next:before
{ 
    font-size: 20px;
    line-height: 1;

    opacity:1;
    color: white;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.slick-prev
{
    left:0;
}
[dir='rtl'] .slick-prev
{
    right:0;
    left: auto;
}
.slick-prev:before
{
    content: '\f104';
}
[dir='rtl'] .slick-prev:before
{
    content: '\f104';
}
.slick-prev::before, .slick-next::before {
	background: #fff;
	font-family: fontawesome;
	position: absolute;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	color: inherit;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 30px;
}
.slick-next
{
    right:0;
}
[dir='rtl'] .slick-next
{
    right: auto;
    left:0;
}
.slick-next:before
{
    content: '\f105';
}
[dir='rtl'] .slick-next:before
{
    content: '\f105';
}

/* Dots */
.slick-dotted.slick-slider
{
    margin-bottom:0px;
}

.slick-dots
{
    position:static;
    bottom:0;

    display: block;

    width: 100%;
    padding: 0;
    margin: 0;

    list-style: none;

    text-align: center;
}
.slick-dots li
{
    position: relative;

    display: inline-block;

    width: 13px;
    height: 13px;
    margin: 0 5px;
    padding: 0;

    cursor: pointer;
}
.slick-dots li button
{
    font-size: 0;
    line-height: 0;

    display: block;

    width: 13px;
    height: 13px;
    padding: 5px;

    cursor: pointer;

    color: transparent;
    border: 0;
    outline: none;
    background: transparent;
}
.slick-dots li button:hover,
.slick-dots li button:focus
{
    outline: none;
}
.slick-dots li button:hover:before,
.slick-dots li button:focus:before
{
    opacity: 1;
}
.slick-dots li button:before
{ 
    font-size:13px;
    line-height:13px;

    position: absolute;
    top: 0;
    left: 0;

    width: 13px;
    height: 13px;

    content: '•';
    text-align: center;

    opacity: .25;
    color:#58aa55;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.slick-dots li.slick-active button:before
{
    opacity:1;
    color: #58aa55;
}

@media screen and (min-width:1200px) {
.tab-Rcatese  .slick-track {
	width: 100% !important;
}
.tab-Rcatese .slick-slide {
	padding: 0 !important; 
	width: auto !important;
}
}
.money-Rinnerse .slick-slide {
	padding:3px 6px;
}
.inner-Bnewse .slick-slide {
	padding: 0;
}
.play-Bodyse { 
	height: 80px;
	-webkit-transition: all 400ms ease-in-out 0s;
	-moz-transition: all 400ms ease-in-out 0s;
	-ms-transition: all 400ms ease-in-out 0s;
	-o-transition: all 400ms ease-in-out 0s;
	transition: all 400ms ease-in-out 0s;

}
.list-Bodyse {
	height:305px;
	-webkit-transition: all 400ms ease-in-out 0s;
	-moz-transition: all 400ms ease-in-out 0s;
	-ms-transition: all 400ms ease-in-out 0s;
	-o-transition: all 400ms ease-in-out 0s;
	transition: all 400ms ease-in-out 0s;

}
.collapse-Tab {
	overflow: hidden;
	height: 0;
	-webkit-transition: all 400ms ease-in-out 0s;
	-moz-transition: all 400ms ease-in-out 0s;
	-ms-transition: all 400ms ease-in-out 0s;
	-o-transition: all 400ms ease-in-out 0s;
	transition: all 400ms ease-in-out 0s;
}
.toplist-Sec .accordion + .accordion {
	margin-top: 15px;
} 
label.error-txt {
	font-size: 13px;
	text-align: left;
	background: #b14344;
	color: #fff;
	display: block;
	border-radius: 3px;
	padding: 11px 15px;
	margin-top: 4px;
	line-height: 13px;
	position: relative;
}
label.error-txt::after {
	content: "";
	background: #b14344;
	width: 10px;
	height: 10px;
	position: absolute;
	left: 15px;
	transform: rotate(45deg);
	top: -3px;
	z-index: 1;
}
/**********************
//Media Query Start//
*************************/
@media screen and (max-width:1540px) {
.ban-Botse {
 position: absolute;
 bottom: 40px;
 left: 0;
 width: 22%;
}
}
@media screen and (max-width:1200px) {
 .ban-Txtwrap > h4 br, .ban-Txtwrap > p br, .title-Betse > p > br {
 display: none;
}
 .inner-Banse {
 padding: 100px 0;
}
.information-Boxse > h3 br, .information-Boxse > p > br {
 display: none;
}
}
 @media screen and (max-width:991px) {
	.login-Wrapse {
		margin: 0 15px;
	}
 .ban-Botse {
 display:none;
}
 .sticky header {
 background: #58aa55;
 position: fixed;
 left: 0;
 right: 0;
 top: 0;
 padding: 10px 0;
 box-shadow: 0 0 3px 2px #2c2c2c;
}
 .logo-Main > a > img {
 max-width: 240px;
}
 .inner-Banse {
 padding: 100px 0 60px;
}
 .main-Menuse ul > li + li > a > img {
 margin-left: 10px;
}
 .main-Menuse ul > li.dropdown > a > img {
 margin:0;
}
 .ban-Txtwrap > h4 {
 font-size: 16px;
 line-height: 20px;
 padding-top: 30px;
 font-weight: 500;
}
.ban-Txtwrap > p {
 font-size: 14px;
 line-height: 19px;
}
 .ban-Txtwrap .play-Btnse > a {
 display: block;
 margin: 0 auto;
 max-width: 300px;
 width: 100%;
}
 .ban-Txtwrap .play-Btnse > a + a {
 margin-left:auto;
 margin-top: 10px;
}
.play-Rmain {
 margin-top: 40px;
 padding: 30px 16px;
 min-height: 340px;
 padding-bottom: 15px;
}
/*********************
// ToplIst Responsive css//
*************************/
.info-Boxse {
 display: inline-block;
 padding:10px 0;
}
.spin-Boxse {
 padding: 10px 0;
}
.list-Boxse .col-lg-4 + .col-lg-4 {
 margin-top: 30px;
}
.rat-Mcercle {
 display:none
}
.ratin-Boxse.wh-Ratse {
 padding-top: 10px;
 flex: 0 0 100%;
}
.ratin-Boxse.wh-Ratse > ul > li > a {
 font-size: 17px;
 line-height: 17px;
 margin: 0 1px;
}
 .information-Boxse {
 padding-bottom: 15px;
}
/*********************
// ToplIst Responsive css end//
*************************/
 
.inner-Bpyse {
 padding:30px 15px 15px;
}
 .ryt-Grnse {
 min-height:auto;
 text-align:left;
}
.reason-Btxtse {
 padding-top: 0;
}
.inner-Bpyse > h2 {
 padding-bottom: 15px;
}
.point-Bmainse {
 padding-top: 20px;
}
.play-Boxtxt {
 margin-top: 8px;
}
.inner-Bpyse.tp-Marse {
 padding:15px;
}
.genric-Binfose > p > span {
 font-size: 14px;
}
.ryt-Grnse .genric-Binfose > p {
 font-size: 14px;
}
.reason-Btxtse > h3 {
 font-size: 14px;
 line-height: 15px;
}
.ryt-Grnse .genric-Binfose > p {
 font-size: 14px;
}
.countSe {
 width: 45px;
 height: 40px;
 font-size: 18px;
 line-height: 18px;
}
.mny-Rtitle > h3 {
 font-size:18px;
}
.title-Betse > p {
 font-size: 16px;
 padding: 20px 0px;
 line-height: 20px;
}
.topRpad {
 padding-top:15px;
}
.play-Boxse {
 padding: 30px 0;
}
.working-Mainse, .news-Rmainse {
 padding: 40px 0 25px;
}
.load-Morese {
 padding-top:40px;
}
.foot-Wraptx {
 padding-top: 40px;
}
.fot-Rwrapse {
 padding-top: 40px;
}
 .fot-Botsec ul > li > span {
 left: 0;
}
.fot-Botsec ul > li {
 font-size: 13px;
}
.fot-Botsec ul > li > a {
 font-size: 13px;
}
.fot-Botsec ul > li > a img {
 max-width: 70px;
}
.main-Menuse ul > li > a > span {
 display: none;
}
.information-Boxse {
 text-align: center;
}
 .play-Header > h3 {
 padding: 15px 20px 15px 45px;
}
.play-Header > h3 > br {
	display: block; 
   }
 .tab-Rmainse #myTab3 li > a > span {
 position: relative;
 width: 35px;
 height: 30px;
 display: block;
 margin: 0 auto 10px;
}
.tab-Rmainse #myTab3 li > a {
 padding:8px 5px;
 display:inline-block;
 width:95px;
 font-size:12px;
}
 .news-Rmainse .load-Morese {
 padding-top:25px;
}
.left-Menuse {
 width: 280px;
}
.right-Menuse, .close_nav .right-Menuse, .right-Menuse1, .close_nav .right-Menuse1, .right-Menuse2, .close_nav .right-Menuse2, .right-Menuse3, .close_nav .right-Menuse3, .right-Menuse4, .close_nav .right-Menuse4, .right-Menuse5, .close_nav .right-Menuse5 {
 width:300px;
}
 .open_side .off-nav > a, .open_side1 .off-nav > a, .open_side2 .off-nav > a, .open_side3 .off-nav > a, .open_side4 .off-nav > a, .open_side5 .off-nav > a {
 transform: translate(-275px);
 z-index: 999999999;
}
.input-Grupse > label > br, .step-Secondse > h3 > br {
 display:inline-block;
}
.top-Uptxt > p > br {
 display: none;
}
.input-Grupse a {
 padding: 20px 15px 20px 62px;
}
.inner-Topfoot {
 margin-top:0;
}
.inner-Bnewse .row {
 margin:0 -8px;
}
.inner-Bnewse .col-lg-4 {
 padding:0 8px;
}
.main-Menuse ul > li > a > span.not-Ficse.m-Block {
 display: flex;
}
.close_nav .tog_nav {
 left: 240px;
}
.step-Secondse.rht-Second2 > p br {
 display: none;
}
.rdetail-Bodyse .row, .genric-Bwrapse .row {
 width:auto;
}
.genric-Bwrapse.ryt-Grnse .row {
 width: 100%;
}

.left-Logse {
 padding: 30px 15px;
}
.close-Modse > a {
 right: 9px;
 top: -15px;
}
.play-Bodyse, .list-Bodyse {
	height:auto;
}
.collapse-Tab {
	overflow: hidden;
	height: 0; 
}
}
 @media screen and (max-width:767px) {
 .inner-Banse {
 padding: 100px 0 40px;
}
 .ban-Txtwrap > h1 {
 font-size: 18px;
 line-height: 22px;
}
 .title-Betse > h2 {
 font-size: 20px;
}
.ply-Txtsec > h3 {
 font-size:20px;
 line-height:normal;
}
.ply-Txtsec > p {
 font-size: 14px;
 line-height: 17px;
}
.title-Betse > p {
 font-size: 14px;
 line-height: 18px;
}
.nwbox-title > h3 {
 font-size: 16px;
 line-height: 18px;
}
.client-Rdetailse > h3, .client-Rmoney > h3, .date-Rmoneyse > h3 {
 font-size: 11px;
}
.logo-Rmoneyse {
 min-height: 60px;
}
.check-Rmoneyse img {
 width: 14px;
 height: 14px;
 margin-top: -5px;
}
.foot-Robse ul > li > a {
 font-size: 13px;
 padding-left: 15px;
}
.fot-Rtitlese > h3 {
 font-size: 13px;
}
.mny-Rtitle > h3 > br {
 display: block;
}
.mobile-Rfinse .row {
 margin:0 -8px;
}
 .mobile-Rfinse .col-6 {
 padding:0 8px;
}
 .bot-Layse {
 margin: 0 -300px -30px;
}
}
 @media screen and (max-width:585px) {
 .age-Breqsec {
 padding: 10px;
}
.age-Breqsec ul > li {
 font-size: 9px;
 line-height: 9px;
 padding: 0 2px;
}
}
 @media screen and (max-width:420px) {
.inner-Pwayse, .inner-Bnewse {
 overflow: hidden;
}
 .pyment-Boxse ul {
 padding-left: 40px;
}
}
.form-control:focus {
	outline: 0;
	box-shadow:none;
}
