.news_items {background:#fff; border:1px solid #b8b7b7; padding:20px; width:360px !important; margin:30px 0 0 0;}
 .news_items h6 {color:#2d2d2c; margin:0;}
.news_items p {font-size:14px;}
.web_date {color:#aeaeae; font-size:12px; margin:10px 0;}
.news_items .btn {min-height:44px; width:100%;}
.slick-slider {
    margin:0 -10px;
}
.slick-slide {
    padding:10px;
}